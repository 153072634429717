import React from "react";
import styled from "styled-components";
import {Col, Row} from "react-bootstrap";
import {Box, Button, Section, Text, Title} from "../../../components/Core";
import {device, theme} from "../../../utils";
import accommodationsPng from "../../../assets/image/flop/event/papc/accommodations.png";

const ImgRight = styled.img`
  max-width: 80%;
  @media ${device.sm} {
    max-width: 80%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
`;

const Accommodation = () => {
    return (
        <Section pt={0} py={0} bg="black" className="position-relative">
            <Box pl={[1, 1, 1, 5, 6]} pt={[2, 2, 2, 2, 2]}>
                <Col>
                    <Row className="justify-content-center align-items-center">
                        <Col lg="6" md="10" sm="9" className="order-lg-2">
                            <Row className={'justify-content-end'}>
                                <div className="text-center text-lg-right position-relative">
                                    <div className="img-main h-100">
                                        <ImgRight
                                            src={accommodationsPng}
                                            alt="First Land Of Poker with Hilton Tallinn Park"
                                        />
                                    </div>
                                </div>
                            </Row>
                        </Col>
                        <Col lg="6" className="order-lg-1" style={{paddingLeft: 20, paddingRight: 20}}>
                            <div
                                data-aos="fade-right"
                                data-aos-duration="750"
                                data-aos-once="true"
                                data-aos-delay="500"
                            >
                                <Box pr={5} pt={[null]}>
                                    <Title color="light" variant="hero" style={{marginBottom: 50}}>
                                        Accommodation
                                    </Title>
                                    <Text color="light" style={{marginBottom: 70}}>
                                        First Land Of Poker in partnership with Hilton Park Tallinn offers multiple hotel room booking options and prices. Check it out !
                                    </Text>
                                </Box>
                            </div>
                            <div className="d-flex flex-column align-items-start mb-3">
                                <a href="http://eventsathilton.com/show/61fce601dbddd1a76f3a6ece">
                                    <Button mb={0} bg="#408597">
                                        Book a room
                                    </Button>
                                </a>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Box>
        </Section>
    );
};

export default Accommodation;
