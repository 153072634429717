import React from "react";
import {Col, Container, Row} from "react-bootstrap";
import {Section, Title, Box, Text} from "../../../components/Core";
import styled from "styled-components";
import schedulePdf from "../../../assets/pdf/PAPC_2022_schedule_final.pdf";
import calendarPng from "../../../assets/image/flop/icons/calendar.png";
import tournament1Png from "../../../assets/image/flop/icons/tournament1.png";
import tournament2Png from "../../../assets/image/flop/icons/tournament2.png";
import tournament3Png from "../../../assets/image/flop/icons/tournament3.png";
import tournament4Png from "../../../assets/image/flop/icons/tournament4.png";
import tournament5Png from "../../../assets/image/flop/icons/tournament5.png";
import tournament6Png from "../../../assets/image/flop/icons/tournament6.png";
import tournament7Png from "../../../assets/image/flop/icons/tournament7.png";
import tournament8Png from "../../../assets/image/flop/icons/tournament8.png";
import tournament9Png from "../../../assets/image/flop/icons/tournament9.png";
import winnerPng from "../../../assets/image/flop/icons/winner.png";
import Badge from "../../../components/Core/Badge";

const Link = styled.a`
    text-decoration: underline;
    color: #408597 !important;
`;

const CardTitle = styled.span`
    font-size: 20px;
    font-weight: Bold;
    position: relative;
    top: 0px;
    width: 80%;
`;
const Date = styled.span`
    color: #7694B2;
    font-size: 14px;
    font-weight: 100;
`;

const CardSubTitle = styled.span`
    color: #7694B2;
    font-size: 14px;
    font-weight: 100;
`;

const FeatureCard = ({icon, title, subTitle, date, price, color, priceBg, ...rest}) => (
    <Box
        pt={'15px'}
        pr={'30px'}
        pl={'20px'}
        borderRadius={5}
        minHeight={165}
        style={{
            boxShadow: '0px 4px 5px 0px rgba(0,0,0,0.15)',
            border: 'solid 1px #D2D9DF'
        }}
        mb={4}
        {...rest}>
        <Box mb={20}>
            <img
                style={{marginRight: 10}}
                src={calendarPng}
                alt="Calendar"
            />
            <Date>{date}</Date>
            {price && (
                <Badge color={color} bg={priceBg} css={{float: 'right'}}>{price}</Badge>
            )}
        </Box>
        <Box mb={15} css={{display: 'flex', flexDirection: 'row'}}>
            <img
                style={{marginRight: 10, height: '100%', width: 'auto',}}
                src={icon}
                alt="Tournament"
            />
            <Box>
                <CardTitle>
                    {title}
                </CardTitle>
                {
                    subTitle && (
                        <Box>
                            <CardSubTitle>({subTitle})</CardSubTitle>
                        </Box>
                    )
                }
            </Box>
        </Box>
    </Box>
);

const KeyDates = () => (
    <>
        <Section bg="white" className={'pb-5'}>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Col lg="6" className="text-center">
                        <Title color={'black'}>
                            Key dates
                        </Title>
                        <Text color={'black'}>
                            For further details and starting times of each of these events, please, see the full&nbsp;
                            <Link href={schedulePdf} target="_blank">tournament schedule</Link>
                        </Text>
                    </Col>
                </Row>
                <Row className="align-items-center justify-content-center">
                    <Col sm="6" md="5" lg="4" className="mt-3">
                        <FeatureCard
                            date={'4th Apr. - 6th Apr.'}
                            price={'€550'}
                            color={'#408597'}
                            priceBg={'#4085974d'}
                            icon={tournament1Png}
                            title={'FLOP Kick-Off Cup'}
                            subTitle={'3 starting flights'}
                        />
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3">
                        <FeatureCard
                            date={'6th Apr.'}
                            price={'€2,500'}
                            color={'#E67E6C'}
                            priceBg={'#e67e6c4d'}
                            icon={tournament2Png}
                            title={'PAPC Bounty Championship'}
                            subTitle={'Shot Clock'}
                        />
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3">
                        <FeatureCard
                            date={'7th Apr.'}
                            price={'€220'}
                            color={'#2D17B5'}
                            priceBg={'#2D17B54d'}
                            icon={tournament3Png}
                            title={'PAPC Deepstack Championship'}
                        />
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3">
                        <FeatureCard
                            date={'8th Apr. - 10th Apr.'}
                            price={'€1,650'}
                            color={'#F18B2D'}
                            priceBg={'#F18B2D4d'}
                            icon={tournament4Png}
                            title={'PAPC Main Event'}
                            subTitle={'2 starting flights'}
                        />
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3">
                        <FeatureCard
                            date={'8th Apr.'}
                            price={'€5,200'}
                            color={'#7CC194'}
                            priceBg={'#7CC1944d'}
                            icon={tournament5Png}
                            title={'PAPC High Roller'}
                        />
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3">
                        <FeatureCard
                            date={'9th Apr.'}
                            price={'€440'}
                            color={'#4D8DCC'}
                            priceBg={'#4D8DCC4d'}
                            icon={tournament6Png}
                            title={'PAPC Progressive Bounty Championship'}
                        />
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3">
                        <FeatureCard
                            date={'9th Apr.'}
                            price={'€1,100'}
                            color={'#82304C'}
                            priceBg={'#82304C4d'}
                            icon={tournament7Png}
                            title={'PAPC Omaha Championship'}
                        />
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3">
                        <FeatureCard
                            date={'10th Apr.'}
                            price={'€220'}
                            color={'#BA57CE'}
                            priceBg={'#BA57CE4d'}
                            icon={tournament8Png}
                            title={'PAPC Queens Cup'}
                            subTitle={'She/Her'}
                        />
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3">
                        <FeatureCard
                            date={'10th Apr.'}
                            price={'€10,300'}
                            color={'#DDB44B'}
                            priceBg={'#DDB44B4d'}
                            icon={tournament9Png}
                            title={'PAPC Super High Roller'}
                        />
                    </Col>
                    <Col sm="6" md="5" lg={{ span: 4, offset: 4 }} className="mt-3">
                        <FeatureCard
                            date={'8th Apr. at 9pm'}
                            icon={winnerPng}
                            title={'Party with Patrik Antonius & Friends'}
                        />
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3 " />
                </Row>
            </Container>
        </Section>
    </>
);

export default KeyDates;
