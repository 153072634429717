import React from "react";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import { Title, Button, Section, Box, Text } from "../../../components/Core";
import papcPresentation from "../../../assets/image/flop/event/papc/papc-presentation.png";
import schedulePdf from "../../../assets/pdf/PAPC_2022_schedule_final.pdf";

const ImgRight = styled.img`
  width: 100%;
`;

const Hero = () => {
  return (
      <Section pt={100} py={0} bg="black" className="position-relative">
        <Box pl={[1, 1, 1, 5, 6]}>
            <Row className="justify-content-center align-items-center">
              <Col lg="6" md="10" sm="9" className="order-lg-2">
                <Row className={'justify-content-end'}>
                  <div className="text-center text-lg-right position-relative">
                    <div
                      className="img-main"
                      data-aos="fade-down"
                      data-aos-duration="750"
                      data-aos-once="true"
                      data-aos-delay="500"
                    >
                      <ImgRight
                        src={papcPresentation}
                        alt="PAPC Presentation"
                      />
                    </div>
                  </div>
                </Row>
              </Col>
              <Col lg="6" className="order-lg-1" style={{paddingLeft: 20, paddingRight: 20}} >
                <div
                  data-aos="fade-right"
                  data-aos-duration="750"
                  data-aos-once="true"
                  data-aos-delay="500"
                >
                  <Box pr={5} pt={[null]}>
                    <Title color="light" variant="hero">
                      The Patrik Antonius Poker Challenge is back!
                      {/*<FlopFormattedMessageWrapper id="events.papc.hero.title" />*/}
                    </Title>
                    <Text color="light" mb={4}>
                      First held in 2019, The Patrik Antonius Poker Challenge (PAPC) returns to the Olympic Park Casino, Tallinn in Spring 2022. Featuring a 29-event tournament schedule including high and low buy-in events, satellites and mix-games, the second edition of PAPC Tallinn will see high rollers and regular players compete side-by-side throughout the week long festival.
                      {/*<FlopFormattedMessageWrapper id="events.papc.hero.subtitle" />*/}
                    </Text>
                  </Box>
                </div>
                <div className="d-flex flex-column align-items-start mb-3">
                  <a href={schedulePdf} target="_blank">
                    <Button mb={0} bg="#408597">
                      See the schedule
                    </Button>
                  </a>
                </div>
              </Col>
            </Row>
        </Box>
      </Section>
  );
};

export default Hero;
