import React from "react";
import papcHighlights1Jpg from "../../../assets/image/flop/event/papc/papc2019/PAPC-101.jpg";
import papcHighlights2Jpg from "../../../assets/image/flop/event/papc/papc2019/PAPC-195.jpg";
import papcHighlights3Jpg from "../../../assets/image/flop/event/papc/papc2019/PAPC-200.jpg";
import papcHighlights4Jpg from "../../../assets/image/flop/event/papc/papc2019/PAPC-401.jpg";
import papcHighlights5Jpg from "../../../assets/image/flop/event/papc/papc2019/PAPC-404.jpg";
import papcHighlights6Jpg from "../../../assets/image/flop/event/papc/papc2019/PAPC-414.jpg";
import papcHighlights7Jpg from "../../../assets/image/flop/event/papc/papc2019/PAPC-608.jpg";
import papcHighlights8Jpg from "../../../assets/image/flop/event/papc/papc2019/PAPC-711.jpg";
import papcHighlights9Jpg from "../../../assets/image/flop/event/papc/papc2019/PAPC-736.jpg";
import papcHighlights10Jpg from "../../../assets/image/flop/event/papc/papc2019/PAPC-807.jpg";
import papcHighlights11Jpg from "../../../assets/image/flop/event/papc/papc2019/PAPC-824.jpg";
import papcHighlights12Jpg from "../../../assets/image/flop/event/papc/papc2019/PAPC-832.jpg";
import papcHighlights13Jpg from "../../../assets/image/flop/event/papc/papc2019/PAPC-833.jpg";
import papcHighlights14Jpg from "../../../assets/image/flop/event/papc/papc2019/PAPC-843.jpg";
import Slider from "react-slick";
import { Container, Row, Col, Image } from "react-bootstrap";
import {Section, Title, Text} from "../../../components/Core/";


export default function PapcHighlights() {
    const PapcHighlightsImages = [
        papcHighlights1Jpg,
        papcHighlights2Jpg,
        papcHighlights3Jpg,
        papcHighlights4Jpg,
        papcHighlights5Jpg,
        papcHighlights6Jpg,
        papcHighlights7Jpg,
        papcHighlights8Jpg,
        papcHighlights9Jpg,
        papcHighlights10Jpg,
        papcHighlights11Jpg,
        papcHighlights12Jpg,
        papcHighlights13Jpg,
        papcHighlights14Jpg,
    ];
    const settings = {
        className: 'center light',
        centerMode: true,
        centerPadding: '60px',
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        speed: 500,
        adaptiveHeight: true,
        arrows: false,
        dots: true,
    };
    return (
        <Section bg="white">
            <Container style={{marginBottom: 40}}>
                <Row className="justify-content-center align-items-center">
                    <Col lg="9" className="text-center">
                        <div>
                            <Title color={
                                'black'
                            }
                            >
                                PAPC 2019 Highlights
                            </Title>
                            <Text color={'black'}>
                                Remember the PAPC 2019? Or maybe you were not there? Here is a quick look at some of
                                the best moments from the event.
                            </Text>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Slider {...settings}>
                        {PapcHighlightsImages.map((item, index) => (
                            <Image src={item} key={index} />
                        ))}
                    </Slider>
                </Row>
            </Container>
        </Section>
    );
}
