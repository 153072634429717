import React from "react";
import { Row, Col } from "react-bootstrap";
import {Section, Button, Text, Title} from "../../../components/Core";
import cashGamesPng from "../../../assets/image/flop/event/papc/cash_game.png"
import Box from "../../../components/Core/Box";
import cashGamesPdf from "../../../assets/pdf/PAPC_Cash_Games.pdf"

const CashGames = () => (
    <Section py={0}  bg="blue5" className="pt-0 mt-0 mb-0 pb-0">
        <Box>
            <Col className={'p-0 m-0'}>
                <Row className="justify-content-center align-items-center">
                    <Col lg="6" md="10" sm="9" className="order-lg-1">
                        <Row className={'justify-content-start'}>
                            <div className="text-center text-lg-left position-relative">
                                <div className="img-main">
                                    <img
                                        src={cashGamesPng}
                                        alt="PAPC Cash Games"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </Row>
                    </Col>
                    <Col lg="6" className="order-lg-2" style={{paddingLeft: 20, paddingRight: 20}}>
                        <div
                            data-aos="fade-left"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="500"
                        >
                            <Box pr={5} pt={[null]}>
                                <Title color="black" variant="hero" style={{marginBottom: 50}}>
                                    Cash Games
                                    {/*<FlopFormattedMessageWrapper id="events.papc.cashGames.title" />*/}
                                </Title>
                                <Text color="black" variant={'card'} mb={4}>
                                    PAPC Cash Games will run around the clock in co-operation with Olympic Park Casino, on the first floor of Hilton Tallinn Park. All game variants will be offered (Texas Holdem, Omaha, Stud, Razz, Open Face Chinese, and much more!)
                                    {/*<FlopFormattedMessageWrapper id="events.papc.hero.subtitle" />*/}
                                </Text>
                                <Text color="black" variant={'card'} mb={5}>
                                    <b>Want more details about the available cash games?</b>
                                    {/*<FlopFormattedMessageWrapper id="events.papc.hero.subtitle1" />*/}
                                </Text>
                            </Box>
                        </div>
                        <div className="d-flex flex-column align-items-start mb-3">
                            <a href={cashGamesPdf} target="_blank" rel="noreferrer">
                                <Button mb={0} bg="#408597">
                                    Discover PAPC Cash Games
                                    {/*<FlopFormattedMessageWrapper id="events.papc.hero.schedule" />*/}
                                </Button>
                            </a>
                            <Box>
                                <Text
                                    fontWeight={200}
                                    fontSize={"14px"}
                                    color="grey"
                                    className="pl-4"
                                >
                                </Text>
                            </Box>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Box>
    </Section>
);

export default CashGames;
