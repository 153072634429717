import React from "react";
import {Col, Row} from "react-bootstrap";
import Section from "../../../components/Core/Section";
import Box from "../../../components/Core/Box";
import Title from "../../../components/Core/Title";
import FlopPostCard from "../../../components/Flop/FlopPostCard";
import jorisRujisPng from "../../../assets/image/flop/event/papc/joris_rujis.png"
import patrikAntoniusPng from "../../../assets/image/flop/event/papc/patrik_antonius.png"
import pauliAyrasPng from "../../../assets/image/flop/event/papc/pauli_ayras.png"
import jorisRujisNamePng from "../../../assets/image/flop/event/papc/joris_rujis_name.png"
import patrikAntoniusNamePng from "../../../assets/image/flop/event/papc/patrik_antonius_name.png"
import pauliAyrasNamePng from "../../../assets/image/flop/event/papc/pauli_ayras_name.png"

const PapcWinners = () => {
    return (
        <Section bg="blue5" py={5} className="position-relative">
            <Box px={[1, 1, 1, 5, 6]}>
                <Row>
                    <Col lg="6" className="text-left pb-3">
                        <div className="">
                            <Title>
                                PAPC 2019 Event Winners
                            </Title>
                        </div>
                    </Col>
                </Row>
                <Row className="align-items-center justify-content-center">
                    <Col xl="3" lg="4" md="6" sm="7" className="mb-4 mx-lg-4">
                        <FlopPostCard
                            img={pauliAyrasPng}
                            imgBrand={pauliAyrasNamePng}
                            title={'25k Super High Roller Winner'}>
                        </FlopPostCard>
                    </Col>
                    <Col xl="3" lg="4" md="6" sm="7" className="mb-4 mx-lg-4">
                        <FlopPostCard
                            img={patrikAntoniusPng}
                            imgBrand={patrikAntoniusNamePng}
                            title={'10k High Roller Winner'}>
                        </FlopPostCard>
                    </Col>
                    <Col xl="3" lg="4" md="6" sm="7" className="mb-4 mx-lg-4">
                        <FlopPostCard
                            img={jorisRujisPng}
                            imgBrand={jorisRujisNamePng}
                            title={'Main Event Winner'}>
                        </FlopPostCard>
                    </Col>
                </Row>
            </Box>
        </Section>
    );
};
export default PapcWinners;
