import React from "react";
import {Container, Row, Col, Image} from "react-bootstrap";

import { Section, Text, Title, Button } from "../../../components/Core";
import olympicParkTallinn1Jpg from "../../../assets/image/flop/event/papc/olympicPark/olympicPark1.jpeg";
import olympicParkTallinn2Jpg from "../../../assets/image/flop/event/papc/olympicPark/olympicPark2.jpeg";
import olympicParkTallinn3Jpg from "../../../assets/image/flop/event/papc/olympicPark/olympicPark3.jpeg";
import olympicParkTallinn4Jpg from "../../../assets/image/flop/event/papc/olympicPark/olympicPark4.jpeg";
import olympicParkTallinn5Jpg from "../../../assets/image/flop/event/papc/olympicPark/olympicPark5.jpeg";
import olympicParkTallinn6Jpg from "../../../assets/image/flop/event/papc/olympicPark/olympicPark6.jpeg";
import olympicParkTallinn7Jpg from "../../../assets/image/flop/event/papc/olympicPark/olympicPark7.jpeg";
import Slider from "react-slick";


export default function OlympicParkTallinn() {
    const OlympicParkTallinnImage = [
        olympicParkTallinn1Jpg,
        olympicParkTallinn2Jpg,
        olympicParkTallinn3Jpg,
        olympicParkTallinn4Jpg,
        olympicParkTallinn5Jpg,
        olympicParkTallinn6Jpg,
        olympicParkTallinn7Jpg,
    ];
    const settings = {
        className: 'center dark',
        centerMode: true,
        centerPadding: '60px',
        infinite: true,
        autoplay: true,
        autoplaySpeed: 4000,
        slidesToShow: 1,
        speed: 500,
        adaptiveHeight: true,
        arrows: false,
        dots: true,
    };
    return (
        <Section bg="black">
            <Container style={{marginBottom: 40}}>
                <Row className="justify-content-center align-items-center">
                    <Col lg="6" className="text-center">
                        <div>
                            <Title color={'white'}>
                                Olympic Park Casino, Tallinn
                            </Title>
                            <Text color={'white'}>
                                Experience poker in one of the nicest places in the city. 18 poker tables, slots, table games, sports bar, cocktail bar and a comfortable lounge, all open 24/7.
                            </Text>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col className="align-items-center">
                        <div className="d-flex flex-column align-items-center my-3">
                            <a href="https://olympic-casino.ee/en/">
                                <Button mb={3} bg="#408597">
                                    Discover Olympic Park Tallinn
                                </Button>
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="justify-content-center align-items-center">
                    <Slider {...settings}>
                        {OlympicParkTallinnImage.map((item, index) => (
                            <Image src={item} key={index} />
                        ))}
                    </Slider>
                </Row>
            </Container>
        </Section>
    );
}
