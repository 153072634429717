import PageWrapper from "../components/PageWrapper";
import Seo from "../components/Seo/seo";
import React from "react";
import Hero from "../sections/flop-events/papc/Hero";
import {Image} from "react-bootstrap";
import papcBannerBng from "../assets/image/flop/event/papc/papc_banner.png";
import CashGames from "../sections/flop-events/papc/CashGames";
import OlympicParkTallinn from "../sections/flop-events/papc/OlympicParkTallinn";
import PapcHighlights from "../sections/flop-events/papc/PapcHighlights";
import PapcWinners from "../sections/flop-events/papc/PapcWinners";
import Accommodation from "../sections/flop-events/papc/Accommodation";
import KeyDates from "../sections/flop-events/papc/KeyDates";
import HowToQualify from "../sections/flop-events/papc/HowToQualify";
import ChallengePatrik from "../sections/flop-events/papc/ChallengePatrik";

const PapcPage = () => {
    return (
        <PageWrapper headerDark bg={'black'}>
            <Seo
                title="Patrik Antonius Poker Challenge"
                desc="The Patrik Antonius Poker Challenge is back!"
            />
            <Hero />
            <Image src={papcBannerBng} alt={'PAPC Banner'} fluid={true} width={'100%'}/>
            <KeyDates />
            <HowToQualify />
            <ChallengePatrik />
            <CashGames />
            <OlympicParkTallinn />
            <PapcHighlights />
            <PapcWinners />
            <Accommodation />
        </PageWrapper>
    );
};
export default PapcPage;
