import {Container, Col, Row} from "react-bootstrap";
import {Button, Text, Title} from "../../../components/Core";
import React from "react";
import {Section, Box} from "../../../components/Core";
import styled from "styled-components";
import directBuyInPng from "../../../assets/image/flop/event/papc/direct_buy_in.png";
import liveSatellitesPng from "../../../assets/image/flop/event/papc/live_satellites.png";
import onlineSatellitesPng from "../../../assets/image/flop/event/papc/online_satellites.png";
import schedulePdf from "../../../assets/pdf/PAPC_2022_schedule_final.pdf";
import unibetSatPdf from "../../../assets/pdf/PAPC_2022_Unibet_Sat_V1.0.pdf";
import olybetSatsPdf from "../../../assets/pdf/PAPC_2022_OlybetSats.pdf";

const CardTitle = styled.div`
    font-size: 18px;
    font-weight: Bold;
`;

const FeatureCard = ({icon, alt, title, text, buttonText, buttonColor, buttonLink, button2Text, button2Color, button2Link}) => (
    <Box>
        <Box className="d-flex justify-content-center align-items-center mx-auto mb-4">
            <img
                height={72}
                width={72}
                src={icon}
                alt={alt}
            />
        </Box>
        <div className="text-center mb-3">
            <CardTitle>
                {title}
            </CardTitle>
        </div>
        <Text variant="small" className="text-center mb-3">
            {text}
        </Text>
        {!button2Text ? (
            <Box className="text-center">
                <a href={buttonLink} target="_blank" >
                    <Button mb={0} bg={buttonColor ? buttonColor : '#408597'}>
                        {buttonText}
                    </Button>
                </a>
            </Box>
        ) : (
            <Row className="align-items-center justify-content-center">
                <Col sm="12" md="12" lg="6" className={'text-center mb-md-2 mb-sm-2 mb-lg-0'}>
                    <a href={buttonLink} target="_blank" >
                        <Button mb={0} bg={buttonColor ? buttonColor : '#408597'}>
                            {buttonText}
                        </Button>
                    </a>
                </Col>
                <Col sm="12" md="12" lg="6" className={'text-center'}>
                    <a href={button2Link} target="_blank" >
                        <Button mb={0} bg={button2Color ? button2Color : '#408597'}>
                            {button2Text}
                        </Button>
                    </a>
                </Col>
            </Row>
        )}

    </Box>
)

const HowToQualify = () => (
    <>
        <Section className="position-relative pt-0">
            <Container>
                <Row className="justify-content-center align-items-center mb-5">
                    <Col lg="12" className="text-center">
                        <Title color={'black'}>
                            How to qualify for the PAPC
                        </Title>
                    </Col>
                </Row>
                <Row className="align-items-center justify-content-center">
                    <Col sm="6" md="5" lg="4" className="mt-3">
                        <FeatureCard
                            icon={directBuyInPng}
                            title={'Direct Buy-In'}
                            alt={'Direct Buy-In'}
                            text={'The simplest way to enter any event at the PAPC Festival Tallinn is to buy-in directly at Olympic Casino Tallinn'}
                            buttonText={'Check Full schedule'}
                            buttonLink={schedulePdf}
                        />
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3">
                        <FeatureCard
                            icon={liveSatellitesPng}
                            title={'Live Satellites'}
                            alt={'Live Satellites'}
                            text={'An alternative way to qualify for key events, including the PAPC Main Event, for a fraction of the usual buy-in'}
                            buttonText={'Check Satellites schedule'}
                            buttonLink={schedulePdf}
                        />
                    </Col>
                    <Col sm="6" md="5" lg="4" className="mt-3">
                        <FeatureCard
                            icon={onlineSatellitesPng}
                            title={'Online satellites'}
                            alt={'Online satellites'}
                            text={'Play online from the comfort of your home and get a chance to qualify for key events at the PAPC'}
                            buttonText={'Unibet'}
                            buttonLink={unibetSatPdf}
                            buttonColor={'#3EAB37'}
                            button2Text={'Olybet'}
                            button2Link={olybetSatsPdf}
                            button2Color={'#E5131A'}
                        />
                    </Col>
                </Row>
            </Container>
        </Section>

    </>
);

export default HowToQualify;
