import Box from "../../../components/Core/Box";
import {Col, Row} from "react-bootstrap";
import challengePatrikJpg from "../../../assets/image/flop/event/papc/challenge-patrik.jpg";
import {Button, Section, Text, Title} from "../../../components/Core";
import challengePatrikPdf from "../../../assets/pdf/PAPC_2022_ChallengePatrik_ HU_V2.0.pdf";
import React from "react";

const ChallengePatrik = () => (
    <Section py={0}  bg="blue5" className="pt-0 mt-0 mb-0 pb-0">
        <Box>
            <Col className={'p-0 m-0'}>
                <Row className="justify-content-center align-items-center">
                    <Col lg="6" md="10" sm="9" className="order-lg-1">
                        <Row className={'justify-content-start'}>
                            <div className="text-center text-lg-left position-relative">
                                <div className="img-main">
                                    <img
                                        src={challengePatrikJpg}
                                        alt="PAPC Cash Games"
                                        className="img-fluid"
                                    />
                                </div>
                            </div>
                        </Row>
                    </Col>
                    <Col lg="6" className="order-lg-2" style={{paddingLeft: 20, paddingRight: 20}}>
                        <div
                            data-aos="fade-left"
                            data-aos-duration="750"
                            data-aos-once="true"
                            data-aos-delay="500"
                        >
                            <Box pr={5} pt={[null]}>
                                <Title color="black" variant="hero" style={{marginBottom: 50}}>
                                    Challenge Patrik
                                </Title>
                                <Text color="black" variant={'card'} mb={4}>
                                    Think you can defeat Patrik Antonius in a heads-up battle?
                                    <br/>
                                    One player will get a chance to take on end-of-level boss Patrik in a best-of-three heads up match.
                                    <br/>
                                    The player who gets this opportunity will be the winner of an exclusive invitational “Challenge Patrik” tournament, wich will take place on Thursday 7th April at the PAPC Festival, Olympic Partk Casino Tallinn, Estonia.
                                </Text>
                            </Box>
                        </div>
                        <div className="d-flex flex-column align-items-start mb-3">
                            <a href={challengePatrikPdf} target="_blank" rel="noreferrer">
                                <Button mb={0} bg="#408597">
                                    Got what it takes ?
                                </Button>
                            </a>
                        </div>
                    </Col>
                </Row>
            </Col>
        </Box>
    </Section>
);

export default ChallengePatrik;
